<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <span
      slot="id"
      slot-scope="text"
    >{{text}}</span>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span slot="active" slot-scope="record"> {{ record.active ? 'Активна' : 'Не активна' }}</span>
    <span slot="alias" slot-scope="record">{{ record.alias }}</span>
    <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
    <span slot="action" slot-scope="record" class="d-flex">
      <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$router.push({ path: `/content/pages/${record.id}`})">
        <i class="fe fe-edit mr-2" />
        Изменить
      </a>
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$router.push({ path: `/content/pages/preview/${record.id}`})">
          <small>
            <i class="fe fe-more-horizontal mr-2" />
          </small>
          Просмотр
        </a>
      <a-popconfirm
        title="Вы уверенны что хотите удалить?"
        ok-text="Да"
        cancel-text="Нет"
        @confirm="$emit('removeItem', record.id)"
      >
        <a href="javascript: void(0);" class="btn btn-sm btn-light d-flex align-items-center">
          <small>
            <i class="fe fe-trash mr-2" />
          </small>
          Удалить
        </a>
      </a-popconfirm>
    </span>
  </a-table>
</template>

<script>

export default {
  name: 'ContentPagesTable',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Статус',
          dataIndex: 'active',
        },
        {
          title: 'Алиас',
          scopedSlots: { customRender: 'alias' },
        },
        {
          title: 'Дата создания',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    getCurrentType(id) {
      const type = this.types.find(item => item.id === id)
      return type ? type.name : ''
    },
  },
}
</script>

<style scoped lang="scss">
</style>
